.links {
  position: absolute;
  right: 2.5rem;
  top: 50%;
  transform: translateY(-50%);
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.links--link {
  color: white;
}

@media (max-width: 800px) {
  .links {
    margin-top: 1.5rem;
    padding-bottom: 1rem;
    right: 0;
    top: auto;
    left: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
  }

  .links--link {
    width: 3rem;
  }
}
