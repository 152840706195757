.experience--type {
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}

.experience--professional,
.experience--academic {
  width: 68vw;
}

@media (max-width: 800px) {
  .experience--professional,
  .experience--academic {
    width: 95%;
  }
}

@media (max-width: 600px) {
  .experience--type {
    margin-bottom: 0px;
    gap: 2rem;
  }
}

@media (max-width: 550px) {
  .button--main {
    font-size: 1rem;
  }
}
