.App {
  padding: 0px 8rem;
}

@media (max-width: 1100px) {
  .App {
    padding: 0.5rem 3rem;
  }
}

@media (max-width: 800px) {
  .App {
    padding: 7.5rem 2rem 0.5rem;
  }
}
