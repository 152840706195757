.button--main {
  display: flex;
  box-sizing: border-box;
  width: 18rem;
  height: 3.75rem;
  justify-content: center;
  align-items: center;
  background-color: var(--grey);
  border: 4px solid var(--light--violet);
  border-radius: 3.125rem;
  color: var(--white);
  font-family: "Pathway Extreme", sans-serif;
  font-size: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0.375rem 0.5rem 0.3rem rgb(0, 0, 0, 0.7);
  cursor: pointer;
  transition-duration: 150ms;
}

.button--main.selected {
  background-color: var(--violet);
  border-color: var(--violet);
}

.button--main:hover:not(.selected) {
  background-color: var(--violet--hover);
  border-color: var(--violet--hover);
}
