.project--card {
  display: flex;
  align-items: center;
}

.project--card--image {
  width: 18.75rem;
  height: auto;
  cursor: pointer;
}

.project--card--text {
  margin-left: 2rem;
}

.project--card--title {
  margin: 0;
  color: var(--violet);
  text-transform: uppercase;
  text-decoration: none;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 600;
  font-size: 2.25rem;
  letter-spacing: 0.05rem;
  transition-duration: 200ms;
}

.project--card--title:hover {
  color: var(--violet--hover);
}

.project--card--desc {
  margin: 0;
  color: var(--white);
  font-size: 1.25rem;
  font-weight: 100;
  letter-spacing: -0.025rem;
}

@media (max-width: 650px) {
  .project--card {
    flex-direction: column;
    margin: 0.5rem auto;
  }

  .project--card--text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .project--card--title,
  .project--card--desc {
    text-align: center;
  }
}
