.phone--navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: #151515;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  z-index: 20;
}

.phone--navbar--logo {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

.phone--navbar--hamburger {
  display: block;
  position: relative;
  /* margin: 1rem 1rem 0 auto; */
  /* position: fixed; */
  /* top: 1rem; */
  /* right: 1rem; */
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  z-index: 15;
}

.phone--navbar--hamburger > span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 83%;
  border-top: 4px solid #cccccc;
  border-radius: 1rem;
  transition: 150ms ease-out;
}

.phone--navbar--hamburger span:nth-child(1) {
  top: 25%;
}
.phone--navbar--hamburger span:nth-child(3) {
  top: 75%;
}

.phone--navbar--hamburger.active span:nth-child(1) {
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.phone--navbar--hamburger.active span:nth-child(2) {
  opacity: 0;
}

.phone--navbar--hamburger.active span:nth-child(3) {
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

@media (min-width: 800px) {
  .phone--navbar {
    display: none;
  }
}

@media (max-width: 500px) {
  .phone--navbar {
    height: 3.33rem;
  }

  .phone--navbar--logo {
    width: 2.08rem;
    height: 2.08rem;
  }
  .phone--navbar--hamburger {
    width: 2.5rem;
    height: 2.5rem;
  }
}
