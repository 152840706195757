.skills--card--title {
  color: var(--violet);
  font-family: "Kumbh Sans", sans-serif;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.skills--card--list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 5rem);
  place-items: center;
  gap: 2rem;
}

.skills--card--image {
  height: 5rem;
}

.qt {
  align-self: center;
  height: 4rem;
}

@media (max-width: 800px) {
  .skills--card--title {
    text-align: center;
  }
  .skills--card--list {
    justify-content: center;
    align-items: center;
  }
}
