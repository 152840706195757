.experience--card--header {
  display: flex;
  align-items: end;
  color: var(--white);
}

.experience--card--header--main {
  display: flex;
  align-items: center;
}

.experience--card--company {
  margin: 0;
  color: var(--red);
  font-size: 2.25rem;
  font-weight: 700;
  letter-spacing: -0.05rem;
}

.experience--card--role::before {
  display: inline-block;
  margin: 1rem 0.75rem 0.325rem 0.85rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.25rem;
  content: "";
  background-color: var(--white);
}

.experience--card--role {
  margin-top: 1rem;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.experience--card--duration {
  margin-top: 0;
  margin-bottom: 1rem;
  margin-left: auto;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  min-width: 13rem;
}

.experience--card--list {
  margin-left: 5rem;
}

.experience--card--list > li {
  margin: 0.75rem auto;
  color: var(--off--white);
  font-family: "Kumbh Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
}

.experience--card--list > li > b {
  color: var(--white);
  font-weight: 600;
}

.experience--card--list > li > a {
  color: var(--blue);
  text-decoration: underline;
  font-weight: 600;
}

@media (max-width: 1530px) {
  .experience--card {
    margin: 2rem auto;
  }

  .experience--card--header {
    align-items: start;
    margin-left: 3rem;
  }

  .experience--card--header--main {
    flex-direction: column;
    align-items: start;
  }

  .experience--card--role::before {
    display: none;
  }

  .experience--card--role {
    margin-top: 0.5rem;
  }
}

@media (max-width: 800px) {
  .experience--card--header {
    flex-direction: column;
    margin-left: 2rem;
  }

  .experience--card--duration {
    margin-left: 0px;
  }

  .experience--card--list {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .experience--card--list > li {
    margin: 0.75rem auto;
  }
}

@media (max-width: 600px) {
  .experience--card--header {
    margin-left: 1rem;
  }
  .experience--card--list {
    margin-left: 1rem;
  }
}
