nav {
  height: 100%;
}

.phone--navbar--menu {
  position: fixed;
  top: 0;
  right: -100vw;
  width: 100vw;
  height: 100%;
  transition: 300ms ease-out;
  background-color: #151515;
  z-index: 10;
}

.phone--navbar--menu.active {
  right: 0;
}

.phone--navbar--menu--items {
  margin: 5rem 2rem;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  list-style-type: none;
}

.phone--navbar--menu--link {
  text-decoration: none;
  color: var(--white);
  font-size: 1.5rem;
}

.phone--navbar--menu--link::after {
  display: block;
  content: "";
  width: 100%;
  height: 5px;
  border-width: 2px;
  border-color: white;
}
