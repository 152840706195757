:root {
  --grey: #202020;
  --white: #ffffff;
  --off--white: #e6e6e6;
  --orange: #ff9f1c;
  --red: #f71735;
  --light--violet: #bfb5ff;
  --violet: #8774ff;
  --violet--hover: rgb(161, 147, 255);
  --blue: #64a2ff;
}

body {
  margin: 0;
  font-family: "Pathway Extreme", "Kumbh Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--grey);
}
