.projects {
  margin-bottom: 2rem;
}

.projects--list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

@media (max-width: 1400px) {
  .projects--list {
    grid-template-columns: repeat(1, 1fr);
  }

  .links {
    margin-top: 4rem;
    padding-bottom: 1rem;
    right: 0;
    top: auto;
    left: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
  }
}
