.home--main {
  position: relative;
}

.home--text {
  position: relative;
}

.home--pretitle {
  margin: 0;
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: 400;
}

.home--title {
  margin: 0;
  color: var(--red);
  font-family: "Kumbh Sans", sans-serif;
  font-size: 6.25rem;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
}

.home--desc {
  width: 60%;
  color: var(--white);
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2em;
}

.home--image {
  display: block;
  position: absolute;
  top: 1rem;
  right: 0;
  width: 26.5vw;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.7);
  border-radius: 0.35rem;
}

.home--buttons {
  margin-top: 4.5rem;
  display: flex;
  gap: 3rem;
}

.home--links {
  margin-top: 6rem;
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.home--link {
  color: white;
}

@media (max-width: 1700px) {
  .home--title {
    font-size: 3.8rem;
  }

  .home--image {
    max-width: 22rem;
  }
}

@media (max-width: 1100px) {
  .home--main {
    display: flex;
    flex-direction: column;
  }

  .home--title {
    font-size: 3.8rem;
  }

  .home--image {
    display: block;
    margin: 0.75rem auto 0;
    position: static;
    width: 25rem;
  }

  .home--desc {
    width: 100%;
    font-size: 1.3rem;
  }

  .home--buttons {
    font-size: 0.25rem;
    color: yellow;
  }

  .home--links {
    align-self: center;
    margin: 3.5rem auto 1rem;
  }
}

@media (max-width: 800px) {
  .home--main {
    display: flex;
    flex-direction: column;
  }

  .home--pretitle {
    font-size: 1.5rem;
  }

  .home--title {
    font-size: 3.25rem;
  }

  .home--image {
    display: block;
    margin: 0.75rem auto 0;
    position: static;
    width: 20rem;
  }

  .home--desc {
    width: 100%;
    font-size: 1.2rem;
    line-height: 1.5em;
  }

  .home--desc > br {
    display: block;
    content: "";
    margin-top: 1em;
  }

  .home--buttons {
    margin-top: 0.5rem;
    align-self: center;
    flex-direction: column;
    gap: 1.5rem;
  }

  .home--links {
    align-self: center;
    margin: 1.5rem auto 1rem;
  }
}

@media (max-width: 550px) {
  .home--title {
    font-size: 2.3rem;
  }
}

@media (max-width: 400px) {
  .home--title {
    font-size: 2rem;
  }

  .home--image {
    width: 15rem;
  }
}
