.navbar {
  margin: 0;
  padding: 0;
  height: 15vh;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar--item {
  font-size: 1.5rem;
  font-weight: 3 00;
  text-transform: uppercase;
}

.navbar--link {
  position: relative;
  text-decoration: none;
  color: var(--white);
}

.navbar--link::after {
  position: absolute;
  top: 2.2rem;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  height: 0.1rem;
  display: inline-block;
  content: "";
  background-color: var(--white);
  transition-delay: 60ms;
  transition-duration: 300ms;
  transition-timing-function: ease-out;
}

.navbar--link:hover::after {
  width: 100%;
}

.navbar--link.selected::after {
  width: 100%;
}

@media (max-width: 800px) {
  .navbar {
    display: none;
  }
}
